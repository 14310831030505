import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import React from "react";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale"
import {ChartCanvas, Chart} from "react-stockcharts"
import {Annotate} from "react-stockcharts/lib/annotation"
import {
    CandlestickSeries,
    LineSeries,
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
    CrossHairCursor,
    EdgeIndicator,
    CurrentCoordinate,
    MouseCoordinateX,
    MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";

import { OHLCTooltip, SingleValueTooltip } from "react-stockcharts/lib/tooltip";
import { ema, sma, macd } from "react-stockcharts/lib/indicator";
import {fitDimensions} from "react-stockcharts/lib/helper"
import client, {ServerResponseCode} from "../../Networking"
import {SvgPathAnnotation} from "react-stockcharts/es/lib/annotation"
import {SizeMe} from "react-sizeme"
import moment from 'moment';
import EventBus from '../../EventBus';
import { getItem as getSettingsItem } from '../../SettingsUtil';
import { userHasActiveSubscription } from '../../AuthenticationUtil.js';

export const chart_types = [
    "t1sec",
    "t5sec",
    "t15sec",
    "t30sec",
    "t1min",
    "t2min",
    "t3min",
    "t4min",
    "t5min",
    "t10min",
    "t15min",
    "t20min",
    "t25min",
    "t30min",
    "t45min",
    "t1h",
    "t90min",
    "t2h",
    "t3h",
    "t4h",
    "t5h",
    "t6h",
    "t7h",
    "t8h",
    "t9h",
    "t10h",
    "t11h",
    "t12h",
    "t13h",
    "t14h",
    "t15h",
    "t16h",
    "t17h",
    "t18h",
    "t19h",
    "t20h",
    "t21h",
    "t22h",
    "t23h",
    "t1day",
    "t30h",
    "t36h",
    "t42h",
    "t2day",
    "t60h",
    "t3day",
    "t4day",
    "t5day",
    "t6day",
    "t1week",
    "t7day",
    "t8day",
    "t9day",
    "t10day",
    "t11day",
    "t12day",
    "t13day",
    "t14day",
    "t15day",
    "t16day",
    "t17day",
    "t18day",
    "t19day",
    "t20day",
    "t21day",
    "t22day",
    "t23day",
    "t24day",
    "t25day",
    "t26day",
    "t27day",
    "t28day",
    "t29day",
    "t30day",
    "t1m",
    "t45day",
    "t60day",
    "t75day",
    "t3m",
    "t120day",
    "t150day",
    "t6m",
    "t210day",
    "t240day",
    "t270day",
    "t300day",
    "t330day",
    "t1y",
    "t18m",
    "t2y",
];

export const secondsForBar = [
    1,
    5,
    15,
    30,
    60, // t1min
    120, // t2min
    180, // t3min
    240, // t4min
    300, // t5min
    600, // t10min
    900, // t15min
    1200, // t20min
    1500, // t25min
    1800, // t30min
    2700, // t45min
    3600, // t1h
    5400, // t90min
    7200, // t2h
    10800, // t3h
    14400, // t4h
    18000, // t5h
    21600, // t6h
    25200, // t7h
    28800, // t8h
    32400, // t9h
    36000, // t10h
    39600, // t11h
    43200, // t12h
    46800, // t13h
    50400, // t14h
    54000, // t15h
    57600, // t16h
    61200, // t17h
    64800, // t18h
    68400, // t19h
    72000, // t20h
    75600, // t21h
    79200, // t22h
    82800, // t23h
    86400, // t1day
    108000, // t30h
    129600, // t36h
    151200, // t42h
    172800, // t2day
    216000, // t60h
    259200, // t3day
    345600, // t4day
    432000, // t5day
    518400, // t6day
    604800, // t1week
    604800, // t7day
    691200, // t8day
    777600, // t9day
    864000, // t10day
    950400, // t11day
    1036800, // t12day
    1123200, // t13day
    1209600, // t14day
    1296000, // t15day
    1382400, // t16day
    1468800, // t17day
    1555200, // t18day
    1641600, // t19day
    1728000, // t20day
    1814400, // t21day
    1900400, // t22day
    1987200, // t23day
    2073600, // t24day
    2160000, // t25day
    2246400, // t26day
    2332800, // t27day
    2419200, // t28day
    2505600, // t29day
    2592000, // t30day
    2592000, // t1m
    3888000, // t45day
    5184000, // t60day
    6480000, // t75day
    7862400, // t3m
    10368000, // t120day
    12960000, // t150day
    15768000, // t6m
    18144000, // t210day
    20736000, // t240day
    23328000, // t270day
    25920000, // t300day
    28512000, // t330day
    31536000, // t1y
    46656000, // t18m
    63072000, // t2y
];

export const batchIntervalDays = [
    1,
    1,
    1,
    1,
    7, // t1min
    7, // t2min
    7, // t3min
    7, // t4min
    14, // t5min
    21, // t10min
    28, // t15min
    42, // t20min
    56, // t25min
    56, // t30min
    84, // t45min
    112, // t1h
    140, // t90min
    168, // t2h
    196, // t3h
    224, // t4h
    280, // t5h
    336, // t6h
    336, // t7h
    336, // t8h
    364, // t9h
    392, // t10h
    420, // t11h
    448, // t12h
    448, // t13h
    448, // t14h
    448, // t15h
    560, // t16h
    560, // t17h
    672, // t18h
    672, // t19h
    672, // t20h
    672, // t21h
    672, // t22h
    672, // t23h
    2 * 365, // t1day
    913, // t30h
    913, // t36h
    913, // t42h
    2 * 548, // t2day
    2 * 548, // t60h
    2 * 548, // t3day
    3 * 548, // t4day
    4 * 548, // t5day
    5 * 548, // t6day
    6 * 365, // t1week
    8 * 365, // t7day
    8 * 365, // t8day
    8 * 365, // t9day
    10 * 365, // t10day
    10 * 365, // t11day
    10 * 365, // t12day
    10 * 365, // t13day
    12 * 365, // t14day
    12 * 365, // t15day
    12 * 365, // t16day
    12 * 365, // t17day
    14 * 365, // t18day
    14 * 365, // t19day
    14 * 365, // t20day
    16 * 365, // t21day
    16 * 365, // t22day
    16 * 365, // t23day
    16 * 365, // t24day
    18 * 365, // t25day
    18 * 365, // t26day
    18 * 365, // t27day
    18 * 365, // t28day
    18 * 365, // t29day
    18 * 365, // t30day
    20 * 365, // t1m
    25 * 365, // t45day
    30 * 365, // t60day
    35 * 365, // t75day
    40 * 365, // t3m
    50 * 365, // t120day
    50 * 365, // t150day
    60 * 365, // t6m
    65 * 365, // t210day
    65 * 365, // t240day
    70 * 365, // t270day
    75 * 365, // t300day
    75 * 365, // t330day
    80 * 365, // t1y
    100 * 365, // t18m
    120 * 365, // t2y
];

// map from chart type to factor
// needs to be adjusted if any dashboards change
const chartHistoricalDataTimeoutFactors = {
    // IntraDay1
    "t1min": 0,
    "t2min": 1,
    "t3min": 2,
    "t4min": 3,
    "t5min": 4,
    "t10min": 5,
    "t15min": 6,
    "t20min": 7,
    "t25min": 8,
    "t30min": 9,
    // IntraDay2
    "t45min": 0,
    "t1h": 1,
    "t90min": 2,
    "t2h": 3,
    "t3h": 4,
    "t4h": 5,
    "t5h": 6,
    "t6h": 7,
    "t7h": 8,
    "t8h": 9,
    // IntraDay3
    "t9h": 0,
    "t10h": 1,
    "t11h": 2,
    "t12h": 3,
    "t13h": 4,
    "t14h": 5,
    "t15h": 6,
    "t16h": 7,
    "t17h": 8,
    "t18h": 9,
    // IntraDay4
    "t19h": 0,
    "t20h": 1,
    "t21h": 2,
    "t22h": 3,
    "t23h": 4,
    "t1day": 5,
    "t36h": 6,
    "t2day": 7,
    "t60h": 8,
    "t3day": 9,
    // ShortTerm
    "t4day": 0,
    "t5day": 1,
    "t1week": 2,
    "t6day": 3,
    "t7day": 4,
    "t8day": 5,
    "t9day": 6,
    "t10day": 7,
    "t11day": 8,
    "t12day": 9,
    // MidTerm
    "t13day": 0,
    "t14day": 1,
    "t15day": 2,
    "t16day": 3,
    "t17day": 4,
    "t18day": 5,
    "t19day": 6,
    "t20day": 7,
    "t21day": 8,
    "t22day": 9,
    // LongTerm1
    "t23day": 0,
    "t24day": 1,
    "t25day": 2,
    "t26day": 3,
    "t27day": 4,
    "t28day": 5,
    "t29day": 6,
    "t30day": 7,
    "t1m": 8,
    "t45day": 9,
    // LongTerm2
    "t60day": 0,
    "t75day": 1,
    "t3m": 2,
    "t120day": 3,
    "t150day": 4,
    "t6m": 5,
    "t210day": 6,
    "t240day": 7,
    "t270day": 8,
    "t300day": 9,
    // LongTerm3
    "t330day": 0,
    "t1y": 1,
    "t18m": 2,
    "t2y": 3,
};

// map from chart type to delay in minutes for the free-tier
const chartDelayMinutes = {
    // IntraDay1
    "t1min": 1,
    "t2min": 2,
    "t3min": 3,
    "t4min": 4,
    "t5min": 5,
    "t10min": 10,
    "t15min": 15,
    "t20min": 20,
    "t25min": 24,
    "t30min": 30,
    // IntraDay2
    "t45min": 45,
    "t1h": 60,
    "t90min": 90,
    "t2h": 120,
    "t3h": 180,
    "t4h": 240,
    "t5h": 300,
    "t6h": 360,
    "t7h": 420,
    "t8h": 480,
    // IntraDay3
    "t9h": 540,
    "t10h": 600,
    "t11h": 660,
    "t12h": 720,
    "t13h": 780,
    "t14h": 840,
    "t15h": 900,
    "t16h": 960,
    "t17h": 1020,
    "t18h": 1080,
    // IntraDay4
    "t19h": 1140,
    "t20h": 1200,
    "t21h": 1260,
    "t22h": 1320,
    "t23h": 1380,
    "t1day": 1440,
};

// map from chart type to delay in bars for the free-tier
const chartDelayBars = {
    // IntraDay1
    "t1min": 20,
    "t2min": 10,
    "t3min": 6,
    "t4min": 5,
    "t5min": 4,
    "t10min": 2,
    "t15min": 2,
    "t20min": 2,
    "t25min": 2,
    "t30min": 2,
    // IntraDay2
    "t45min": 2,
    "t1h": 2,
    "t90min": 2,
    "t2h": 2,
    "t3h": 2,
    "t4h": 2,
    "t5h": 2,
    "t6h": 2,
    "t7h": 2,
    "t8h": 2,
    // IntraDay3
    "t9h": 2,
    "t10h": 2,
    "t11h": 2,
    "t12h": 2,
    "t13h": 2,
    "t14h": 2,
    "t15h": 2,
    "t16h": 2,
    "t17h": 2,
    "t18h": 2,
    // IntraDay4
    "t19h": 2,
    "t20h": 2,
    "t21h": 2,
    "t22h": 2,
    "t23h": 2,
    "t1day": 1,
};

const HISTORICAL_DATA_LOAD_TIMEOUT = 5 * 1000;  // in milliseconds

export const categories = [
    "EquityUS",
    "EquityNonUS",
    "Forex",
    "Crypto"
];

export const getOHLCAndVolumeFromData = (data) => {
    if (!data) {
        return null;
    }

    let timestamp = data.timestamp;

    if (!timestamp) {
        return null;
    }

    let ts = (timestamp.seconds) * 1000 + timestamp.nanos / 1000;

    const ohlc = {
        date: new Date(ts),
        open: data.open,
        high: data.high,
        low: data.low,
        close: data.close,
        volume: data.volume,
        indicators: data.indicators,
        signal: data.signal,
        trendStrength: data.trendStrength,
    };

    if (data.indicators) {
        data.indicators.forEach((d, i) => {
            const indicator = indicators[i];
            if (indicator && indicatorsMap[indicator.name]) {
                ohlc[indicatorsMap[indicator.name].property] = d.indicatorValues[0];
            }
        });
    }

    return ohlc;
};

function getMaxUndefined(calculators) {
    return calculators.map(each => each.undefinedLength()).reduce((a, b) => Math.max(a, b));
}
const LENGTH_TO_SHOW = 180;

const macdAppearance = {
    stroke: {
        macd: "#FF0000",
        signal: "#00F300",
    },
    fill: {
        divergence: "#4682B4"
    },
};

export const indicators = [ {name: "SMA"}, {name: "MFI"}, {name: "MA1"}, {name: "MA2"}, ];

const indicatorsMap = {
    SMA: {
        property: "sma"
    },
    MFI: {
        property: "mfi"
    },
    /*  the MA1 value is now set differently
    MA1: {
        property: "ma1"
    },*/
    MA2: {
        property: "ma2"
    }
}

function rewindDateDays(date, daysCount) {
    var result = new Date(date);
    result.setDate(date.getDate() - daysCount);
    result.setHours(23, 59, 59, 0);
    return result;
}

// static variable serves for generating unique chart ids (fixes issue when ids are not unique)
let sChartId = 1;

class CandleStickChartPanToLoadMore extends React.Component {

    constructor(props) {
        super(props)

        this.onParentResize = this.onParentResize.bind(this)
        this.resize = this.resize.bind(this)
        this.onMessage = this.onMessage.bind(this)
        this.onSubscribe = this.onSubscribe.bind(this)
        this.getHistoricalData = this.getHistoricalData.bind(this)
        this.unsubscribe = this.unsubscribe.bind(this)
        this.chartId = sChartId++

        if (this.props.onSizeElement) {
            this.props.onSizeElement.addListener(this)
        }

        this.historicalOhlc = {}
        this.handleDownloadMore = this.handleDownloadMore.bind(this);

        this.state = {
            data: [],
            step: chart_types.indexOf(this.props.step || 4),
            receivedHistoricalData: false,
        }

        this.updatedLinearData = [];
    }

    componentDidMount() {
        if (this.props.symbol) {
            //console.log('set timeout interval 1: ', chartHistoricalDataTimeoutFactors[this.props.step], this.props.step);
            this.historicalSetupTimerId = setTimeout(() => {
                this.getHistoricalData(this.onMergeCurrentDataWithHistoricalData);
                delete this.historicalSetupTimerId;

                if (!userHasActiveSubscription()) {
                    const delayInMinutes = chartDelayMinutes[this.props.step] || 0;
                    if (delayInMinutes > 0) {
                        this.historicalFreeTierIntervalId = setInterval(() => {
                            this.getHistoricalData(this.onMergeCurrentDataWithHistoricalData, null, new Date(), true);
                        }, delayInMinutes * 60 * 1000);
                    }
                }
            }, this.props.waitForLoad ? (chartHistoricalDataTimeoutFactors[this.props.step] * HISTORICAL_DATA_LOAD_TIMEOUT) : 1);
        }
        EventBus.on("localStorageUpdated", this.onLocalStorageUpdated);
    }

    onLocalStorageUpdated = (updatedField) => {
        if (updatedField === "ma_lines_visible") {
            this.setState(this.state);
        }
    }

    getHistoricalData(
        onCompleted,
        startDate = null,
        endDate = new Date(),
        secondary= false
    ) {
        console.log("getHistoricalData()")
        if (this.unmounted) {
            return
        }

        const symbol = this.props.symbol;
        const { step } = this.state;

        let timerId = setInterval((() => {
            if (client.isAlive && !this.unmounted) {
                const rewindedDate = rewindDateDays(endDate, batchIntervalDays[step]);
                if (!startDate || rewindedDate.getTime() < startDate.getTime()) {
                    startDate = rewindedDate;
                }
                if (startDate.valueOf() < 0) {
                    startDate = new Date(0);
                }
                
                console.log(`Getting data for chart seconds ${secondsForBar[step]} from ${startDate} to ${endDate}`)

                if (/*!this.subscribeId*/!this.subscribeCallbackFunction) {
                    this.subscribe(this.props.symbol, this.state.step, indicators)
                }

                let request = client.getHistoricalData(
                    symbol,
                    indicators,
                    step,
                    {seconds: Math.floor(startDate.valueOf()/1000) },
                    {seconds: Math.floor((endDate).valueOf()/1000)},
                    this.onMessage
                );

                let requestId = request.id
                if (!this.historicalOhlc[requestId]) {
                    this.historicalOhlc[requestId] = []
                }

                request.promise
                    .then(() => {
                        console.log("historicalOhlc", this.historicalOhlc[requestId]);
                        if (this.historicalOhlc[requestId].length <= 1 && !secondary) {
                            const newStartDate = rewindDateDays(endDate, batchIntervalDays[step]);
                            this.getHistoricalData(onCompleted, newStartDate, endDate, true)
                        } else {
                            onCompleted(this.historicalOhlc[requestId])
                            if (this.props.onHistoricalDataLoaded)
                                this.props.onHistoricalDataLoaded();
                            delete this.historicalOhlc[requestId]
                        }
                    })
                    .catch((err)=> {
                        //this.subscribeId = null;
                        this.subscribeCallbackFunction = null;
                        console.error(err);
                        if (!this.unmounted) {
                            this.getHistoricalData(onCompleted);
                        }
                    })
                this.setState({...this.state, symbol})
                clearInterval(this.timerId)
                delete this.timerId
            }
        }).bind(this), 100);
        this.timerId = timerId;
    }

    generateSubscribeCallback = (symbol, step, indicators) => {
        return (data) => {
            if (symbol == data.symbol && step == data.step)
                this.onSubscribe(data.response);
        };
    };

    subscribe(symbol, step, indicators) {
        /*let {id} = client.subscribe(symbol, step, indicators, this.onSubscribe);
        this.subscribeId = id;*/
        this.subscribeCallbackFunction = this.generateSubscribeCallback(symbol, step, indicators);
        EventBus.on("realTimeUpdate", this.subscribeCallbackFunction);
    }

    onSubscribe = async (response) => {
        this.setState((state) => {
            //console.log("onSubscribe()")
            if (this.unmounted) {
                return;
            }
    
            //console.log({ response });
            let ohlcv = getOHLCAndVolumeFromData(response.data);
            //console.log(ohlcv ? ohlcv.date : null);
            //console.log({ ohlcv });
    
            let data = state.data;
    
            // me: this is useless since updatedLinearData array is never updated and has a length of 0
            if (this.updatedLinearData.length > 0) {
                data = this.updatedLinearData;
            }
    
            if (response.code === ServerResponseCode.NEW_STEP) {
                console.log("ServerResponseCode.NEW_STEP, return");
                return;
            }

            if (!this.state.receivedHistoricalData) {
                console.log("live point, no historical data");
                return;
            }
            
            if (data.length > 0 && ohlcv) {
                const currentCandle = data[data.length - 1];
                //console.log(currentCandle ? currentCandle.date : null);
                // are we getting all the updated OHLCV prices from the BE,
                // it certainly looks like
                const currentStepInSeconds = secondsForBar[state.step];
                //const timeDifferenceInSeconds = moment(ohlcv.date).diff(moment(currentCandle.date).startOf('minutes'), 'seconds');
                const currentCandleDateSeconds = currentCandle.date.getTime() / 1000;
                const currentCandleDateAdjusted = new Date((currentCandleDateSeconds - currentCandleDateSeconds % currentStepInSeconds) * 1000);
                const timeDifferenceInSeconds = moment.duration(
                        moment(ohlcv.date).diff(moment(currentCandleDateAdjusted))
                    ).asSeconds();
                //console.log({ ohlcDate: moment(ohlcv.date).toDate() });
                //console.log({ currentCandleStartof: moment(currentCandle.date).startOf('minutes').toDate() });
                //console.log({ currentStepInSeconds });
                //console.log({ timeDifferenceInSeconds });
                if (timeDifferenceInSeconds >= currentStepInSeconds) {
                //     console.log(currentCandle.date);
                //     console.log(ohlcv.date);
                //     console.log("timeDifference > currentStepInSeconds");
                //     console.log("ADDING new element here");
                // // if (response.code === ServerResponseCode.NEW_STEP) {
                // //     console.log("ServerResponseCode.NEW_STEP");
                //     currentCandle.date.setSeconds(0);
                //     currentCandle.date.setMilliseconds(0);
                    // let endDate = new Date;
                    // const startDate = new Date(endDate.getTime() - secondsForBar[this.state.step] * 5 * 1000);
                    // endDate = lastElem.date;
                    // endDate.setSeconds(0);
                    // endDate.setMilliseconds(0);
                    // lastElem.date = endDate;
                    //console.log("Adding a new candle", ohlcv);
                    data = data.concat({
                        ...ohlcv,
                    });
                } else {
                    // this will update the last candle
                    //console.log("Updating the last candle", ohlcv);
                    if (ohlcv) {
                        data = data.slice(0, data.length - 1);
                        data = data.concat({
                            ...ohlcv,
                        });
                    }
                }
            } else if (ohlcv) {
                data = data.concat({ ...ohlcv });
            }

            if (data.length > 0 || ohlcv) {
                // update the ma1 indicator and signal values for previous points
                const lastOHLC = data[data.length - 1];
                const ma1Array = lastOHLC.indicators[2].indicatorValues;
                const signalArray = lastOHLC.signal;
                lastOHLC.ma1 = ma1Array[0] ? ma1Array[0] : 0;
                lastOHLC.signal2 = signalArray[0] ? signalArray[0] : 0;
                for (let i = 1, len = ma1Array.length; i < len; ++i) {
                    if (data.length - 1 - i >= 0) {
                        data[data.length - 1 - i].ma1 = ma1Array[i];
                    } else {
                        break;
                    }
                }
                for (let i = 1, len = signalArray.length; i < len; ++i) {
                    if (data.length - 1 - i >= 0) {
                        data[data.length - 1 - i].signal2 = signalArray[i];
                    } else {
                        break;
                    }
                }
                return { data };
            }
        })
    }

    componentWillUnmount() {
        console.log("componentWillUnmount()");
        this.unsubscribe();
        this.unmounted = true;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        if (this.historicalSetupTimerId) {
            clearTimeout(this.historicalSetupTimerId);
            delete this.historicalSetupTimerId;
        }
        if (this.historicalFreeTierIntervalId) {
            clearInterval(this.historicalFreeTierIntervalId);
            delete this.historicalFreeTierIntervalId;
        }
        EventBus.remove("localStorageUpdated", this.onLocalStorageUpdated);
        Object.keys(this.historicalOhlc).forEach((k) => client.cancel(+k));
    }

    unsubscribe = () => {
        console.log("unsubscribe()");
        EventBus.remove("realTimeUpdate", this.subscribeCallbackFunction);
        this.subscribeCallbackFunction = null;
        /*const subscribeId = this.subscribeId;
        if (subscribeId) {
            this.subscribeId = null;
            return client.cancel(subscribeId).promise;
        }
        return new Promise((resolve)=> resolve());*/
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!(prevProps.symbol) || prevProps.symbol.quote !== this.props.symbol.quote) && this.props.symbol) {
            this.historicalOhlc = []
            this.unsubscribe()
            this.setState({
                ema26: null,
                ema12: null,
                macdCalculator: null,
                smaVolume50: null,
                xScale : null,
                xAccessor: null, displayXAccessor: null,
                data: []
            }, () => {
                //console.log('set timeout interval 2: ', chartHistoricalDataTimeoutFactors[this.props.step], this.props.step);
                this.historicalSetupTimerId = setTimeout(() => {
                    this.getHistoricalData((historicalData)=>{
                        this.state.data = []
                        this.onMergeCurrentDataWithHistoricalData(historicalData)
                        delete this.historicalSetupTimerId;
                    });
                }, this.props.waitForLoad ? (chartHistoricalDataTimeoutFactors[this.props.step] * HISTORICAL_DATA_LOAD_TIMEOUT) : 1);
            })
            this.forceUpdate();
            //this.setState({step: this.state.step, data: []})
        }
    }

    sortFunction(a, b) {
        if (a.date < b.date) {
            return -1
        }

        return 1
    }

    onMergeCurrentDataWithHistoricalData = (historicalData) => {
        if (this.unmounted) {
            return;
        }

        // TODO: flatten data

        let allOhlc = this.state.data;

        // if (allOhlc.length === 0) {
        //     allOhlc = historicalData.concat([{date: new Date}])
        // }
        // else {
                allOhlc = historicalData.concat(this.state.data);
        // }

        // filter duplicate entries (it happens when subscription starts sending data before history is retrieved)
        let lookupMap = allOhlc.map(el => true);
        let seenDates = [];
        allOhlc.forEach((el, index) => {
            if (seenDates.indexOf(el.date.getTime()) === -1) {
                seenDates.push(el.date.getTime());
            } else {
                lookupMap[index] = false;
            }
        });
        allOhlc = allOhlc.filter((el, index) => lookupMap[index]);

        allOhlc.sort(this.sortFunction);

        // from oldest to newest, that is how updating must be made
        for (let i = 0, len = allOhlc.length; i < len; ++i) {
            const ohlc = allOhlc[i];
            const ma1Array = ohlc.indicators[2].indicatorValues;
            const signalArray = ohlc.signal;
            ohlc.ma1 = ma1Array[0] ? ma1Array[0] : 0;
            ohlc.signal2 = signalArray[0] ? signalArray[0] : 0;
            for (let j = 1, lenj = ma1Array.length; j < lenj; ++j) {
                if (i - j >= 0) {
                    allOhlc[i - j].ma1 = ma1Array[j];
                } else {
                    break;
                }
            }
            for (let j = 1, lenj = signalArray.length; j < lenj; ++j) {
                if (i - j >= 0) {
                    allOhlc[i - j].signal2 = signalArray[j];
                } else {
                    break;
                }
            }
        }

        // if free tier, cut off some data
        if (!userHasActiveSubscription()) {
            const trimBars = chartDelayBars[this.props.step] || 0;
            if (trimBars > 0) {
                allOhlc = allOhlc.slice(0, allOhlc.length - trimBars);
            }
        }

        //console.log(allOhlc)
        this.setState({ data: allOhlc, receivedHistoricalData: true });
        // this.lastUpdatedCandleDate = allOhlc[allOhlc.length - 1].date;
        //this.applyData(allOhlc)
        //this.setState({...this.state, show: true, data: allOhlc, symbol: this.state.symbol})
        if (/*!this.subscribeId*/!this.subscribeCallbackFunction) {
            this.subscribe(this.props.symbol, this.state.step)
        }
    }

    onMessage = (response) => {
        if (response.code === ServerResponseCode.FAIL) {
            // TODO process error
            console.log("response.code = ServerResponseCode.FAIL");
            this.setState({ ...this.state, show: true });
        }
        if (response.code === ServerResponseCode.STREAMING_LAST) {
            console.log("response.code = ServerResponseCode.STREAMING_LAST");
            return;
        }

        const ohlcv = getOHLCAndVolumeFromData(response.data);
        if (ohlcv) {
            this.addData(ohlcv, this.historicalOhlc[response.replyToID])
        }
    }

    addData = (ohlc, historicalOhlc) => {
        // me: commented the 1 line below, its useless
        // const ohlcData = historicalOhlc
        // if (ohlcData.length)
        // {
        //     if (ohlcData[ohlcData.length - 1][0] < ohlc[0]) {
        //         console.log("erere")
        //         return
        //     }
        // }
        // if (volumeData.length)
        // {
        //     if (volumeData[volumeData.length - 1][0] < volume[0])
        //         return
        // }
        // me: I replaced this with unshift
        // ohlcData.splice(0, 0, ohlc)
        if (historicalOhlc)
            historicalOhlc.unshift(ohlc);

        // ohlcData.push(ohlc)
        // volumeData.push(volume)
    }

    setData = (volume, ohlc) => {
        this.setState({ ...this.state, volume, ohlc})
    }

    setValue = e => {
        this.setState({ value: e.target.value });
    };

    setContainerTitle = () => {
        this.props.glContainer.setTitle(this.state.value);
    }

    resize = (width, height) => {
        this.setState({...this.state, width, height})
    }

    onParentResize = () => {
        const el = this.props.onSizeElement;
        this.setState({
            ...this.state,
            width: el.innerWidth,
            height: el.innerHeight
        });
    }

    handleDownloadMore(start, end) {
        //console.log("handleDownloadMore (prohibited)", { start, end });
        return; // currently this functionality is disabled in order for the indicator lines to be continuous

        if (this.requestStarted) {
            return
        }

        if (Math.ceil(start) === end) {
            return;
        }

        this.requestStarted = true

        const { data: data } = this.state;

        const rowsToDownload = end - Math.ceil(start);
        console.log("rows to download", rowsToDownload, start, end)

        const newStartDate = rewindDateDays(data[0].date, batchIntervalDays[this.state.step]);
        this.getHistoricalData((historicalOhlc) => {
            let inputData = historicalOhlc
            if (this.state.data && this.state.data.length > 0) {
                const firstDate = this.state.data[0].date
                inputData = inputData.filter((d) => {
                    return d.date < firstDate
                })
            }

            if (inputData.length === 0) {
                this.requestStarted = false
                return
            }
            // console.log(this.historicalOhlc)
            inputData.sort(this.sortFunction)
            this.setState({data: inputData.concat(data)})
            this.requestStarted = false
        }, newStartDate, data[0].date)
    }

    changeScroll() {
        let style = document.body.style.overflow
        document.body.style.overflow = (style === 'hidden') ? 'auto':'hidden'
    }

    generateBuySellPaths() {
        var halfWidth = 7;
        var bottomWidth = 2;
        var height = 18;
    
        const buyPath = (_ref) => {
            var x = _ref.x,
                y = _ref.y + 3;
    
            return "M" + x + " " + y + " " + ("L" + (x + halfWidth) + " " + (y + halfWidth) + " ") + ("L" + (x + bottomWidth) + " " + (y + halfWidth) + " ") + ("L" + (x + bottomWidth) + " " + (y + height) + " ") + ("L" + (x - bottomWidth) + " " + (y + height) + " ") + ("L" + (x - bottomWidth) + " " + (y + halfWidth) + " ") + ("L" + (x - halfWidth) + " " + (y + halfWidth) + " ") + "Z";
        }
    
        const sellPath = (_ref2) => {
            var x = _ref2.x,
                y = _ref2.y - 3;
    
            return "M" + x + " " + y + " " + ("L" + (x + halfWidth) + " " + (y - halfWidth) + " ") + ("L" + (x + bottomWidth) + " " + (y - halfWidth) + " ") + ("L" + (x + bottomWidth) + " " + (y - height) + " ") + ("L" + (x - bottomWidth) + " " + (y - height) + " ") + ("L" + (x - bottomWidth) + " " + (y - halfWidth) + " ") + ("L" + (x - halfWidth) + " " + (y - halfWidth) + " ") + "Z";
        }
    
        return { buyPath, sellPath }
    }

    render() {
        const type = "hybrid"
        const width = this.props.width
        const height = this.props.height

        const maLinesSetting = getSettingsItem("ma_lines_visible")
        const haveMaLines = !maLinesSetting || maLinesSetting === 'true'

        const ratio = this.props.ratio
        if (!this.state) {
            return <div/>
        }

        const initialData = this.state.data;

        const ema26 = ema()
            .id(0)
            .options({ windowSize: 26 })
            .merge((d, c) => { d.ema26 = c; })
            .accessor(d => d.ema26);

        const ema12 = ema()
            .id(1)
            .options({ windowSize: 12 })
            .merge((d, c) => {d.ema12 = c;})
            .accessor(d => d.ema12);

        const smaInd = sma()
            .id(2)
            .options({windowSize: 30})
            .accessor(d => d.sma)

        const macdCalculator = macd()
            .options({
                fast: 12,
                slow: 26,
                signal: 9,
            })
            .merge((d, c) => {d.macd = c;})
            .accessor(d => d.macd);

        const smaVolume50 = sma()
            .id(3)
            .options({
                windowSize: 50,
                sourcePath: "volume",
            })
            .merge((d, c) => {d.smaVolume50 = c;})
            .accessor(d => d.smaVolume50);

        const calculatedData = smaInd(smaVolume50(macdCalculator(ema12(ema26(initialData)))));
        const xScaleProvider = discontinuousTimeScaleProvider
            .inputDateAccessor(d => d.date);
        const {
            data,
            xScale,
            xAccessor,
            displayXAccessor,
        } = xScaleProvider(calculatedData);

        let yTickFormat = undefined
        const generateFormatter = (maxDecimalPlaces) => {
            return (n) => {
                if (n === null || n === undefined)
                    return '';
                let num = null;
                if (!isNaN(n)) {
                    num = n;
                } else if (typeof n.valueOf === 'function') {
                    const value = n.valueOf();
                    if (!isNaN(value)) {
                        num = value;
                    } else {
                        return '';
                    }
                } else {
                    return '';
                }
                const maxLength = 7 // 6 digits plus decimal separator
                let currentDigits = maxDecimalPlaces
                let current = ''
                do {
                    if (currentDigits <= 0) {
                        return ''
                    }
                    const baseFormatter = format(`.${currentDigits}f`)
                    current = baseFormatter(num)
                    --currentDigits
                } while (current.length > maxLength)
                return current
            }
        }
        if (this.state.symbol) {
            if (this.state.symbol.category === "EquityUS" || this.state.symbol.category === "EquityNonUS") {
                yTickFormat = generateFormatter(2) // format(".2f")
            } else if (this.state.symbol.category === "Forex") {
                yTickFormat = generateFormatter(5) // format(".5f")
            } else {    // Crypto
                yTickFormat = generateFormatter(1) // format(".1f")
            }
        }

        const yGrid = {
            innerTickSize:   -1 * width ,
            tickStrokeDasharray: 'Solid',
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1,
            tickFormat: yTickFormat,
            tickStroke: "#909fa7",
        }
        const xGrid = {
            innerTickSize:  -1 * height,
            tickStrokeDasharray: 'Solid',
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1,
            tickStroke: "#909fa7",
            stroke: "#5F5F5F",
        }

        const { buyPath, sellPath } = this.generateBuySellPaths()

        const longAnnotationProps = {
            y: ({ yScale, datum }) => yScale(datum.low),
            fill: "#22AA42",
            path: buyPath,
            tooltip: "Go long",
        };

        const shortAnnotationProps = {
            y: ({ yScale, datum }) => yScale(datum.high),
            fill: "#FF0000",
            path: sellPath,
            tooltip: "Go short",
        };

        const isForexSymbol = this.state.symbol && this.state.symbol.category === "Forex";

        let labelTrendValue = "";
        let labelTrendValueColor = "";
        let labelTrendStrength = "";
        if (data && data.length) {
            const lastOHLC = data[data.length - 1];
            const trendStrength = lastOHLC.trendStrength[0];
            if (trendStrength > 0) {
                labelTrendValue = "Up";
                labelTrendValueColor = "#22AA42";
            } else if (trendStrength < 0) {
                labelTrendValue = "Down";
                labelTrendValueColor = "#FE0606";
            } else {
                labelTrendValue = "N/A";
            }
            const trendStrengthFormat = !isForexSymbol ? '.2f' : '.5f';
            labelTrendStrength = format(trendStrengthFormat)(trendStrength);
            while (labelTrendStrength.length > 0 && (labelTrendStrength[labelTrendStrength.length - 1] === '0' || labelTrendStrength[labelTrendStrength.length - 1] === '.')) {
                labelTrendStrength = labelTrendStrength.substring(0, labelTrendStrength.length - 1);
            }
            if (!labelTrendStrength) labelTrendStrength = '0';
        }

        return (
            <SizeMe monitorHeight>
                {({size}) => <div style={{position: "relative", height: "100%"}}
                         /*onMouseEnter={this.changeScroll}
                         onMouseLeave={this.changeScroll}*/
                    >
                        {
                            this.state.data && this.state.data.length > 2 &&
                            <ChartCanvas ratio={ratio} width={size.width} height={size.height}
                                         type={type}
                                         seriesName={this.state.symbol.quote}
                                         data={data}
                                         xScale={xScale} xAccessor={xAccessor}
                                         xExtents={data && data.length > 0 ? [
                                            Math.max(data.length - (size.width >= 800 ? 224 : 40), 0),
                                            data.length + (size.width >= 800 ? 16 : 10)
                                        ] : undefined}
                                         displayXAccessor={displayXAccessor}
                                         margin={{
                                            left: size.width >= 800 ? 50 : 30,
                                            top: 20,
                                            right: this.state.symbol ? ( this.state.symbol.category === 'Forex' || this.state.symbol.category === 'Crypto' ? 60 : 50) : 50,
                                            bottom: 30,
                                        }}
                                         onLoadMore={this.handleDownloadMore}>
                                <Chart id={this.chartId} height={size.height - 50} yPan
                                       yExtents={[d => [d.high, d.low], ema26.accessor(), ema12.accessor()]}
                                       padding={{top: 10, bottom: 10}}
                                       >
                                    <XAxis axisAt="bottom" orient="bottom"  {...xGrid}/>
                                    <YAxis axisAt="right" orient="right" {...yGrid}/>

                                    <MouseCoordinateX
                                        at="bottom"
                                        orient="bottom"
                                        displayFormat={timeFormat("%Y-%m-%d %H:%M")} />

                                    <MouseCoordinateY
                                        at="right"
                                        orient="right"
                                        displayFormat={yTickFormat} />
                                    {
                                        <Annotate with={SvgPathAnnotation} when={d => d.signal2 === 1}
                                                  usingProps={longAnnotationProps}/>
                                    }
                                    {
                                        <Annotate with={SvgPathAnnotation} when={d => d.signal2 === 2}
                                                  usingProps={shortAnnotationProps}/>
                                    }

                                    <CandlestickSeries/>

                                    {/*<LineSeries yAccessor={ema26.accessor()} stroke={ema26.stroke()}/>*/}
                                    {/*<LineSeries yAccessor={ema12.accessor()} stroke={ema12.stroke()}/>*/}
                                    {/*<LineSeries yAccessor={smaInd.accessor()} stroke={smaInd.stroke()}/>*/}
                                    { haveMaLines && <LineSeries yAccessor={(d) => d.ma1} stroke={"#1338BE"}/> }
                                    { haveMaLines && <LineSeries yAccessor={(d) => d.ma2} stroke={"#FF0000"}/> }

                                    {/*<CurrentCoordinate yAccessor={ema26.accessor()} fill={ema26.stroke()}/>*/}
                                    {/*<CurrentCoordinate yAccessor={ema12.accessor()} fill={ema12.stroke()}/>*/}
                                    {/*<CurrentCoordinate yAccessor={smaInd.accessor()} fill={smaInd.stroke()}/>*/}
                                    <CurrentCoordinate yAccessor={(d) => d.ma1} fill={"#1338BE"}/>
                                    <CurrentCoordinate yAccessor={(d) => d.ma2} fill={"#FF0000"}/>
                                    
                                    <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                                                    yAccessor={d => d.close}
                                                    fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}
                                                    displayFormat={yTickFormat} />
                                    

                                    { size.width >= 800 && size.height >= 600 &&
                                        <OHLCTooltip origin={[-20, 0]} xDisplayFormat={timeFormat("%Y-%m-%d %H:%M")}
                                                    ohlcFormat={format(".3f")}
                                        />
                                    }

                                    <SingleValueTooltip
                                                yLabel={"Trend"}
                                                yAccessor={d => labelTrendValue}
                                                yDisplayFormat={d => d}
                                                displayValuesFor={() => ({})}
                                                valueFill={labelTrendValueColor}
                                                className={"svg-bold-custom"}
                                                origin={(w, h) => [w - 165, 5]} />

                                    <SingleValueTooltip
                                                yLabel={"Strength"}
                                                yAccessor={d => labelTrendStrength}
                                                yDisplayFormat={d => d}
                                                displayValuesFor={() => ({})}
                                                valueFill={labelTrendValueColor}
                                                className={"svg-bold-custom"}
                                                origin={(w, h) => [w - 95, 5]} />
                                    {/*
                                    <MovingAverageTooltip
                                        onClick={(e) => {
                                            console.log(e)
                                            this.setState({...this.state, showMFI: !this.state.showMFI})
                                        }}
                                        origin={[-38, 15]}
                                        options={[
                                            // {
                                            //     yAccessor: ema26.accessor(),
                                            //     type: ema26.type(),
                                            //     stroke: ema26.stroke(),
                                            //     ...ema26.options(),
                                            // },
                                            // {
                                            //     yAccessor: ema12.accessor(),
                                            //     type: ema12.type(),
                                            //     stroke: ema12.stroke(),
                                            //     ...ema12.options()
                                            // },
                                            {
                                                type: "MA1",
                                                stroke: "#1338BE",
                                                windowSize: 0,
                                                yAccessor: (d) => d.ma1
                                            },
                                            {
                                                type: "MA2",
                                                stroke: "#FF0000",
                                                windowSize: 0,
                                                yAccessor: (d) => d.ma2
                                            }
                                        ]}
                                    /> */}
                                    {/*<TrendLineIndicator/>*/}
                                </Chart>
                                {/*<Chart id={2} height={150}*/}
                                {/*yExtents={[d => d.volume, smaVolume50.accessor()]}*/}
                                {/*origin={(w, h) => [0, h - 150]}>*/}
                                {/*<YAxis axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")}/>*/}

                                {/*<MouseCoordinateY*/}
                                {/*at="left"*/}
                                {/*orient="left"*/}
                                {/*displayFormat={format(".4s")}/>*/}

                                {/*<BarSeries yAccessor={d => d.volume} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>*/}
                                {/*<AreaSeries yAccessor={smaVolume50.accessor()} stroke={smaVolume50.stroke()}*/}
                                {/*fill={smaVolume50.fill()}/>*/}
                                {/*</Chart>*/}
                                <CrossHairCursor/>
                            </ChartCanvas>
                        }
                    </div>
                }
            </SizeMe>
        )
    }
}

CandleStickChartPanToLoadMore = fitDimensions(CandleStickChartPanToLoadMore);

export default CandleStickChartPanToLoadMore;
