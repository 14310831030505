import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';

import Menu from '../../Menu.js';
import { isAuthenticated } from '../../AuthenticationUtil.js';

//import EventBus from '../../EventBus';
//import { setItem as setSettingsItem, getItem as getSettingsItem } from '../../SettingsUtil';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
)

/** Non-link normal items for the sidebar */
const SidebarItemIconNoLink = ({item, isActive, onClick}) => (
    <li className={ isActive ? 'sidebar-nav-li-el active' : 'sidebar-nav-li-el' } onClick={onClick} style={{display: 'block', height: '66px', textAlign: 'center'}}>
        {item.icon && <em title={item.name} className={item.icon} style={{fontSize: '22px', marginTop: '20px'}}></em>}
    </li>
)

/** New Popup window button*/
const SideBarNewPopupItem = ({item, isActive, action}) => (
    <li className={ isActive ? 'active' : '' }>
        <a onClick={action}>
            {/*<em className="icon-graph"/>*/}
            <span>{item}</span>
        </a>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

class Sidebar extends Component {

    state = {
        collapse: {},
        popouts: []
    }

    constructor(props) {
        super(props)
        this.current_id = 1
        this.addPopout = this.addPopout.bind(this)
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator, this.closeSidebar, this.addPopout);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
        Object.keys(this.props.settings).forEach(k => {
            this.props.actions.changeSetting(k, this.props.settings[k])
        })
    }

    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse});
    }

    navigator = route => {
        this.props.history.push(route);
    }

    addPopout = () => {
        this.props.actions.addPopout()
        // let popouts = this.state.popouts
        // popouts.push({id : this.current_id++, parent: this})
        // this.setState({...this.state, popouts})
    }

    onClosePopup() {
        let widget = this
        let popouts = widget.parent.state.popouts
        popouts = popouts.filter(p => p !==  widget)
        widget.parent.setState({...this.state, popouts})
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    /*onCliClicked = () => {
        const localStorageValue = getSettingsItem('ma_lines_visible')
        if (!localStorageValue || localStorageValue === 'true') {
            setSettingsItem('ma_lines_visible', 'false')
        } else {
            setSettingsItem('ma_lines_visible', 'true')
        }
        EventBus.dispatch('localStorageUpdated', 'ma_lines_visible')
        this.setState(this.state);
    }*/

    render() {
        /*const cilItem = {
            name: 'Chart Indicator Lines',
            icon: 'fa fa-chart-line',
        }*/
        //const localStorageValue = getSettingsItem('ma_lines_visible')
        //const isCilActive = !localStorageValue || localStorageValue === 'true'
        // TODO: make dynamic
        const authMenuItem = !isAuthenticated() ? {
            name: 'Login',
            icon: 'icon-power',
            path: '/login'
        } : {
            name: 'Logout',
            icon: 'icon-power',
            path: '/logout'
        };
        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }
                            <SidebarItemHeader item={Menu[0]}/>
                            {/*<SidebarItemIconNoLink isActive={isCilActive} item={cilItem} onClick={this.onCliClicked} />*/}
                            {/*<SidebarItem isActive={false} item={Menu[1]} action={this.addPopout} />*/}
                            {/*<li>
                                <div className="nav-item">
                                    {Menu[1].icon && <em className={Menu[1].icon}></em>}
                                </div>
                                <Collapse isOpen={Menu[1].isOpen}>
                                    <ul className="sidebar-nav sidebar-subnav">
                                        <SideBarNewPopupItem isActive={false} item={"New Chart"} action={this.addPopout}/>
                                    </ul>
                                </Collapse>
                            </li>
                            */}
                            {/*<SideBarNewPopupItem isActive={false} item={Menu[1]} action={this.addPopout} />*/}
                            { /* Show popouts */ }
                            {/*{*/}
                                {/*this.state.popouts.map((widget) =>*/}
                                    {/*<PopoutComponent key={widget.id} onClose={this.onClosePopup.bind(widget)}>*/}
                                    {/*</PopoutComponent>*/}
                                {/*)*/}
                            {/*}*/}
                        </ul>
                        <ul className="sidebar-nav sidebar-position-bottom">
                        
                            {/* <SidebarItem isActive={this.routeActive(Menu[2].path)} item={Menu[2]} /> */}
                            <SidebarItem isActive={this.routeActive(authMenuItem.path)} item={authMenuItem} />
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces('translations')(withRouter(Sidebar)));
