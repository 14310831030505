const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Forum',
        icon: 'icon-graph',
        path: '/forum',
    },
    {
        name: 'Forum',
        icon: 'icon-question',
        path: '/forum'
    },
];

export default Menu;