import React from 'react';
import { /* Redirect, */ Route } from 'react-router-dom';
// import { isAuthenticated, userHasFullAccess } from './AuthenticationUtil';

export const ProtectedAuthorizedRoute = (props) => {
    /*if (isAuthenticated()) {
        const hasFullAccess = userHasFullAccess();
        if (!hasFullAccess && props.path !== '/Main') {
            return <Redirect to="/Main" />;
        } else {
            return <Route {...props} />;
        }
    } else {
        return <Redirect to="/login" />;
    }*/
    return <Route {...props} />;
};