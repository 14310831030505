
import {AppContext} from "../../appContext"
import {GoldenLayoutComponent} from "../golden-layout-wrapper/goldenLayoutComponent"
import * as React from "react"
import LiveTV from "../GLWidgets/LiveTV"
import LiveChat from "../GLWidgets/LiveChat"
import FinancialCalendar from "../GLWidgets/FinancialCalendar"
import NewsFeeds from "../GLWidgets/NewsFeeds"
import PeriodChart, {chart_types} from "../Charts/PeriodChart"
import {connect} from "react-redux"
import EventBus from '../../EventBus';
import RealTimeSubscription from "../../RealTimeSubscription";
import { ActiveCharts } from '../../RealTimeSubscription'
import { userHasActiveSubscription } from "../../AuthenticationUtil"

import { setItem as setSettingsItem, getItem as getSettingsItem } from '../../SettingsUtil';

class LayoutCommon extends React.Component {
    constructor(props) {
        super(props);
        this.lastSymbol = null;
        this.layoutDefaultConfig = this.props.config
        this.dashboardName = this.props.name
        if (this.dashboardName === 'MainDashBoard') {
            const persistedPeriod = getSettingsItem('mainDashboardPeriod');
            this.currentPeriod = persistedPeriod ? persistedPeriod : 't1day'
        } else {
            this.currentPeriod = null
        }
        this.currentConfig = this.layoutDefaultConfig
        this.currentComponentKey = 0
        
        this.layout = null

        let savedState = getSettingsItem(`${this.dashboardName}State`)
        if (savedState) {
            this.currentConfig = JSON.parse(savedState);
        }
        this.onResize = this.onResize.bind(this)
    }

    componentWillMount (){
        if (this.dashboardName === 'MainDashBoard') {
            EventBus.on('localStorageUpdated', this.onLocalStorageUpdated);
        }
        this.setState({height: window.innerHeight});
    }

    onLocalStorageUpdated = (updatedField) => {
        if (updatedField === 'mainDashboardPeriod') {
            const newPeriod = getSettingsItem(updatedField);
            this.currentPeriod = newPeriod;
            this.setState(this.state);
        }
    }

    updateWidthOffsidebar = (isOpen) => {
        const doc = document.querySelector(".section-container");
        if (doc) {
            doc.style.marginRight = isOpen ? '253px' : '0';
            //this.layout.updateSize()
        }
    }

    componentDidMount() {
        if (!this.lastSymbol || this.props.symbol != this.lastSymbol) {
            EventBus.dispatch('symbolChanged', this.props.symbol);
            if (userHasActiveSubscription())
                setTimeout(() => {
                    RealTimeSubscription.initialize(this.props.symbol);
                }, 1000);
            this.lastSymbol = this.props.symbol;
        }

        if (this.props) {
            this.updateWidthOffsidebar(this.props.offsidebarOpen);
        }

        setTimeout(() => {
            this.onResize();
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.symbol != this.props.symbol) {
            EventBus.dispatch('symbolChanged', this.props.symbol);
            RealTimeSubscription.terminate();
            if (userHasActiveSubscription())
                RealTimeSubscription.initialize(this.props.symbol);
            this.lastSymbol = this.props.symbol;
        }

        if (prevProps.offsidebarOpen !== this.props.offsidebarOpen) {
            this.updateWidthOffsidebar(this.props.offsidebarOpen);
            this.layout.updateSize();
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        EventBus.remove('localStorageUpdated', this.onLocalStorageUpdated);
        //RealTimeSubscription.terminate();
    }

    onResize = () => {
        this.setState({height: window.innerHeight })
        this.layout.updateSize()
    }

    createChartTitle = period => {
        const chartObj = ActiveCharts.find(el => el.step === period);
        return `${chartObj.value} ${chartObj.unit.toLowerCase()}${chartObj.value > 1 ? 's' : ''}`;
    }

    createPeriodTitle = period => {
        const chartObj = ActiveCharts.find(el => el.step === period);
        return `Chart_${chartObj.step}`;
    }

    generateActualConfig = () => {
        if (this.dashboardName !== 'MainDashBoard')
            return this.currentConfig
        return {
            settings: {
                ...this.currentConfig.settings,
            },
            content: [
                {
                    type: "row",
                    content: [
                        {
                            type: "column",
                            width: 100,
                            content: [
                                {
                                    title: this.createChartTitle(this.currentPeriod),
                                    type: "react-component",
                                    component: this.createPeriodTitle(this.currentPeriod),
                                    isClosable: false,
                                }
                            ]
                        },
                        /*{
                            type: "column",
                            width: 20,
                            content: [
                                {
                                    type: "row",
                                    height: 60,
                                    content: [
                                        {
                                            title: "Watchlist",
                                            type: "react-component",
                                            component: "LiveTV"
                                        }
                                    ]
                                },
                                {
                                    type: "row",
                                    height: 40,
                                    content: [
                                        {
                                            title: "News",
                                            type: "react-component",
                                            component: "NewsFeeds"
                                        }
                                    ]
                                }
                            ]
                        }*/
                    ],
                }
            ]
        }
    }

    generateActualKey = () => {
        if (this.dashboardName !== 'MainDashBoard') {
            return 0;
        }
        return ++this.currentComponentKey;
    }

    render() {
        const height =  (this.state.height - 115) + 'px'
        const actualConfig = this.generateActualConfig()
        const actualKey = this.generateActualKey()  // enables making changes without having to refresh the page
        return <AppContext.Provider value={this.state["contextValue"]} key={actualKey}>
            <GoldenLayoutComponent
                htmlAttrs={{ style: { maxHeight: "100%", width: "100vp", height: height}  }}
                config={actualConfig}
                registerComponents={myLayout => {
                    this.layout = myLayout
                    myLayout.registerComponent("LiveTV", LiveTV)
                    myLayout.registerComponent("LiveChat", LiveChat)
                    myLayout.registerComponent("FinancialCalendar", FinancialCalendar)
                    myLayout.registerComponent("NewsFeeds", NewsFeeds)

                    chart_types.forEach((step) => {
                        myLayout.registerComponent("Chart_" + step, () =>
                            <PeriodChart step={step} symbol={this.props.symbol} waitForLoad={this.dashboardName !== 'MainDashBoard'} />)
                    } )

                    window.addEventListener('resize', this.onResize)
                    myLayout.on('stateChanged', () => {
                      try {
                        let state = JSON.stringify( myLayout.toConfig())
                        setSettingsItem(`${this.dashboardName}State`, state);
                      } catch(err) {
                        console.log("LayoutCommon: myLayout.on('stateChanged') err");
                        console.log(err);
                      }
                    })
                }}
            />
        </AppContext.Provider>
    }
}

const mapStateToProps = state => (
    {
        symbol: state.settings.currentSymbol,
        offsidebarOpen: state.settings.offsidebarOpen,
    })

export default connect(mapStateToProps, {})(LayoutCommon)